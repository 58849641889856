import React, { useState,useContext } from 'react';
import { useParams } from 'react-router-dom';
import AIFormUpdated from '../AIFormUpdated';
import { Container, Typography, TextField, Button, Paper } from '@mui/material';
import { AuthContext } from '../../AuthContext';
import Cookies from 'js-cookie';
import AiFormUpdateProd from '../AiFormUpdateProd';

export default function ClientValidation() {
  const [aiFormData, setAiFormData] = useState({});
  const { client_url } = useParams();
  const [verification, setVerification] = useState(Cookies.get(client_url) ? Cookies.get(client_url) : false);
  const { clientverify} = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`/auth/aiform/frontend/clientverify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_url,
          client_pin: aiFormData.client_pin,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error('Server error');
        return;
      } else {
        if (data.status) {
          setVerification(!verification);
          clientverify(client_url,data.status)
        } else {
          alert(data.message);
        }
      }
    } catch (error) {
      console.error('Error in newSession:', error);
    }
  };

  if (verification) {
    return <AiFormUpdateProd />;
  } else {
    return (
      <Container
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: '#f7f7f7',
          maxWidth:"100%",
          
          padding: 0, // Remove padding for full coverage
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 3,
            textAlign: 'center',
            backgroundColor: '#ffffff',
            maxWidth: 400, // Limit the width of the paper
            width: '100%', // Make it responsive
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            Verification
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Please verify client credentials:
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="client_pin"
              label="Client PIN"
              value={aiFormData.client_pin || ''}
              onChange={(e) => setAiFormData({ ...aiFormData, client_pin: e.target.value })}
              required
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Verify
            </Button>
          </form>
        </Paper>
      </Container>
    );
  }
}
