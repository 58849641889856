import React, { useContext } from 'react';
import LanguageHeader from './LanguageHeader';
import { AuthContext } from '../../AuthContext';

export default function Header() {
    const { authState, logout } = useContext(AuthContext);

    if (authState.token) {
        return (
            <div className="header">
                <div className="header-left">
                    <div
                        className="search-toggle-icon bi bi-search"
                        data-toggle="header_search"
                    ></div>
                    <div className="header-search">
                        <LanguageHeader />
                    </div>
                </div>
                <div className="header-right">
                    {/* <div className="dashboard-setting user-notification">
                        <div className="dropdown">
                            <a
                                className="dropdown-toggle no-arrow"
                                href="javascript:void(0)"
                                data-toggle="right-sidebar"
                            >
                                <i className="dw dw-settings2"></i>
                            </a>
                        </div>
                    </div> */}
                    {/* <div className="user-notification">
                        <div className="dropdown">
                            <a
                                className="dropdown-toggle no-arrow"
                                href="#"
                                role="button"
                                data-toggle="dropdown"
                            >
                                <i className="icon-copy dw dw-notification"></i>
                                <span className="badge notification-active"></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="notification-list mx-h-350 customscroll">
                                    <ul>
                                        {[
                                            { imgSrc: "/vendors/images/img.jpg", name: "John Doe", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                            { imgSrc: "/vendors/images/photo1.jpg", name: "Lea R. Frith", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                            { imgSrc: "/vendors/images/photo2.jpg", name: "Erik L. Richards", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                            { imgSrc: "/vendors/images/photo3.jpg", name: "John Doe", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                            { imgSrc: "/vendors/images/photo4.jpg", name: "Renee I. Hansen", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                            { imgSrc: "/vendors/images/img.jpg", name: "Vicki M. Coleman", msg: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed..." },
                                        ].map((notification, index) => (
                                            <li key={index}>
                                                <a href="#">
                                                    <img src={notification.imgSrc} alt="" />
                                                    <h3>{notification.name}</h3>
                                                    <p>{notification.msg}</p>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="user-info-dropdown">
                        <div className="dropdown">
                            <a
                                className="dropdown-toggle"
                                href="#"
                                role="button"
                                data-toggle="dropdown"
                            >
                                <span className="user-icon">
                                    <img src="/vendors/images/photo1.jpg" alt="" />
                                </span>
                                <span className="user-name">
                                    {authState.user?.first_name} {authState.user?.last_name}
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a className="dropdown-item" href={`/view-profile/${authState.user?._id}`}>
                                    <i className="dw dw-user1"></i> Profile
                                </a>
                                <a className="dropdown-item" href="/backend">
                                    <i className="dw dw-settings2"></i> Setting
                                </a>
                               
                                <a className="dropdown-item" href="javascript:void(0)" onClick={logout}>
                                    <i className="dw dw-logout"></i> Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="github-link">
                        <a href="https://github.com/dropways/deskapp" target="_blank" rel="noopener noreferrer">
                            <img src="vendors/images/github.svg" alt="" />
                        </a>
                    </div> */}
                </div>
            </div>
        );
    } else {
        return (
            <div className="login-header box-shadow">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <div className="brand-logo">
                        <a href="login.html">
                            <img src="vendors/images/deskapp-logo.svg" alt="" />
                        </a>
                    </div>
                   
                </div>
            </div>
        );
    }
}
