import React, { useEffect, useState, useRef } from "react";
import * as io from "socket.io-client";
import { useDispatch,useSelector } from 'react-redux';
import UseToast from "../UseToast";
import UseTranslationData from "../UseTranslationData";

const sampleRate = 16000;

const getMediaStream = () =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: "default",
      sampleRate: sampleRate,
      sampleSize: 16,
      channelCount: 1,
    },
    video: false,
  });

const AudioToText = ({
  siteBrandColor,
  onstartConverting,
  onstopRecording,
  translationData,
  aiFormData,
  onplayPromoMessage,  
  onsessionInfo,
  // onSpeechRecognitionEnd,
  onstartspeech,
  rizwanScriptStep,
  setonstartspeech,
  rizwanScriptStepEnglishRef
}) => {


  const [connection, setConnection] = useState(null);
  const [currentRecognition, setCurrentRecognition] = useState("");
  const [currentRecognitionLang, setCurrentRecognitionLang] = useState("");

  const [recognitionHistory, setRecognitionHistory] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const processorRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioInputRef = useRef(null);
  const recordingTimeoutRef = useRef(null); // Timer for auto-stop after 10 seconds
  const inactivityTimer = useRef(null); // Timer for inactivity
  const silenceTimer = useRef(null); // Timer for silence
  const onsessionInfoRef = useRef(onsessionInfo);
  const rizwanScriptStepRef= useRef(rizwanScriptStep);

  const detectIsFinal= useRef(false);

  


  const inactivityTimeLimit = aiFormData?.promo_time_limit; // Default to 10 seconds
  const blankSpaceTime = aiFormData?.blank_space; // Default to 5 seconds

  const currentRecognitionRef = useRef("");
  const currentRecognitionLangRef = useRef("");
  const currentRecognitionLangListenRef = useRef('');

  const { showSuccessToast, showWarningToast } = UseToast(); // Use the custom hook

  const [audioDataFinal,setAudioDataFinal]=useState({});

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const words = "Great, thanks for arriving early! I've let Amy know you are here. She's just wrapping up with another customer. In the meantime, is there anything I can help you with?".split(" ");
  const timerRef = useRef(null);


  useEffect(() => {
    if (rizwanScriptStepEnglishRef) {
      setCurrentWordIndex(0); // Reset word index when it becomes true
      timerRef.current = setInterval(() => {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
      }, 380); // Adjust the speed here (500ms = 0.5 second per word)
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current); // Clean up on unmount
    };
  }, [rizwanScriptStepEnglishRef]);

  useEffect(() => {
    setCurrentText(words.slice(0, currentWordIndex).join(" "));
  }, [currentWordIndex]);



useEffect(()=>{
  rizwanScriptStepRef.current=rizwanScriptStep;
},[rizwanScriptStep])

useEffect(()=>{

  detectIsFinal.current = audioDataFinal?.isFinal ? true: false

},[audioDataFinal])
  

useEffect(() => {
  currentRecognitionRef.current = currentRecognition;
}, [currentRecognition]);

useEffect(() => {
  currentRecognitionLangRef.current = currentRecognitionLang;

  const listenWord=UseTranslationData(aiFormData,currentRecognitionLang.split('-')[0]);
  currentRecognitionLangListenRef.current = listenWord?.listening_text;
}, [currentRecognitionLang]);

  useEffect(() => {
    onsessionInfoRef.current = onsessionInfo;
  }, [onsessionInfo]);

  useEffect(()=>{
    if(onstartspeech){ 
      
   disconnect();      
     connect();
    }
  },[onstartspeech])


  const playPromoMessage = () => {

    disconnect(); // Stop the recording
    onplayPromoMessage(onsessionInfoRef.current);
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(
      playPromoMessage,
      inactivityTimeLimit * 60* 1000 // Convert to milliseconds
    );
  };

  const resetSilenceTimer = () => {    

    if(aiFormData.enable_rizwan_event!=='yes'){

      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
      silenceTimer.current = setTimeout(
        stopRecordingDueToSilence,
        blankSpaceTime * 1000 // Convert to milliseconds
      );

    }
  };

  const stopRecordingDueToSilence = () => {
    console.log("Silence detected. Stopping the recording...");
    const finalRecognition = currentRecognitionRef.current; // Accessing the current recognition value from the ref

    const finalRecognitionLang = currentRecognitionLangRef.current;

    console.log("finalRecognitionLang", finalRecognitionLang); // This should now log the correct value
  
    // if (onSpeechRecognitionEnd) {
    //   onSpeechRecognitionEnd(finalRecognition); // Pass the final recognition value to the parent
    //   onstopRecording(finalRecognition,finalRecognitionLang.split('-')[0]);

    //  // setonstartspeech();
    // }
    //disconnect(); // Stop the recording

    if(finalRecognition ){

     
      onstopRecording(finalRecognition,finalRecognitionLang.split('-')[0]);
      if(aiFormData.enable_rizwan_event==='yes'){

        currentRecognitionRef.current = "";
        setCurrentRecognition('');
        setonstartspeech();

      }
      
      //setCurrentRecognition("");

      

      disconnect();

    }
    
  };

  const speechRecognized = (data) => {
    if (data.isFinal) {
      setCurrentRecognition(data.text);
      setRecognitionHistory((old) => [data.text, ...old]);
      setCurrentRecognitionLang(data.languageCode[0]);
      //resetSilenceTimer();

      if(aiFormData.enable_rizwan_event==='yes'){

        stopRecordingDueToSilence();

      }

       

      //setAudioDataFinal(data);

      
      
     
    } else {
      setCurrentRecognition(data.text);

      setCurrentRecognitionLang(data.languageCode[0]);
      //detectIsFinal.current=false;
     // setAudioDataFinal({});
      
       
      

    }
    resetInactivityTimer(); // Reset inactivity timer whenever speech is detected
    resetSilenceTimer(); // Reset silence timer whenever speech is detected
  };

  // On Click start

  const connect = () => {

    if (connection) connection.disconnect();
    //const socket = io.connect("https://d8e9-2409-40d1-b7-10d0-9146-9a67-fab-c03f.ngrok-free.app"); // Replace with your server's IP address

   const socket = io.connect("https://aih.holomedia.ai");

    
    socket.on("connect", () => {
      console.log("connected", socket.id);
      currentRecognitionLangListenRef.current='';
      if(aiFormData.enable_rizwan_event==='yes'){
        showSuccessToast('Please Speak Now!');

      }
      
      setConnection(socket);
      onstartConverting(onsessionInfo)

      // Send session info or any other data
    const data = { 
      sessionInfo: onsessionInfoRef.current,
      rizwanScriptStep: rizwanScriptStepRef.current, 
       rizwanStatus: aiFormData.enable_rizwan_event==='yes' ? true : false || false ,
      //rizwanStatus: true 
    };
    socket.emit("startGoogleCloudStream", data);

    });

    // socket.emit("startGoogleCloudStream");

    socket.on("receive_audio_text", (data) => {
      
      console.log("received audio text", data);

      speechRecognized(data);
    });

    socket.on("disconnect", () => {
      console.log("disconnected", socket.id);
    });
  };


  const disconnect = () => {

    

    if (!connection) return;
    
    connection.emit("endGoogleCloudStream");
    connection.disconnect();
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    audioContextRef.current?.close();
    setConnection(null);
    setIsRecording(false);
    
  
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
    }
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    if (silenceTimer.current) {
      clearTimeout(silenceTimer.current);
    }
  };

  useEffect(() => {
    resetInactivityTimer(); // Start inactivity timer when component mounts

    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== "closed") {
          audioContextRef.current?.close();
        }
      }
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
    };
  }, [isRecording]);

  useEffect(() => {
    (async () => {
      if (connection && !isRecording) {
        const stream = await getMediaStream();

        audioContextRef.current = new window.AudioContext();

        await audioContextRef.current.audioWorklet.addModule(
          "/src/worklets/recorderWorkletProcessor.js"
        );

        audioContextRef.current.resume();

        audioInputRef.current =
          audioContextRef.current.createMediaStreamSource(stream);

        processorRef.current = new AudioWorkletNode(
          audioContextRef.current,
          "recorder.worklet"
        );

        processorRef.current.connect(audioContextRef.current.destination);
        audioContextRef.current.resume();

        audioInputRef.current.connect(processorRef.current);

        processorRef.current.port.onmessage = (event) => {
          const audioData = event.data;
          connection.emit("send_audio_data", { audio: audioData });
        };

        setIsRecording(true);

        if(aiFormData.enable_rizwan_event!=='yes'){

          // Automatically stop recording after 10 seconds
            recordingTimeoutRef.current = setTimeout(() => {
              disconnect();
            }, 10000); // 10000 milliseconds = 10 seconds

        }

        

        resetInactivityTimer(); // Start inactivity timer when recording starts
        resetSilenceTimer(); // Start silence timer when recording starts
      } else {
        console.error("No connection");
      }
    })();
    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== "closed") {
          audioContextRef.current?.close();
        }
      }
    };
  }, [connection, isRecording]);

  return (
    <div>
      {aiFormData?.enable_speech_btn==='yes' && (
        <>

       
         
          <button type="button" className="ai_starttalk" id="ai_starttalk" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' ? 'none' : ''
            
          }}  
          onClick={connect}>
          {translationData?.ask_your_question}
    
          </button> 
      
    
          <button type="button" className="ai_starttalk" id="stoprecording" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' ? 'none' : ''
            
            }} onClick={disconnect}>
          {currentRecognitionLangListenRef.current ? currentRecognitionLangListenRef.current: 'Listening...'}
          </button>
          
     

      <div id="textresponse">
      {
        aiFormData.enable_rizwan_event === 'yes' && rizwanScriptStepEnglishRef
          ? currentText
          : aiFormData.enable_rizwan_event === 'yes' && !rizwanScriptStepEnglishRef ? '' : currentRecognitionRef.current
          
      }

      </div>
      </>

      )}
      
    </div>
  );
};

export default AudioToText;
