import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AIForm from './Component/AIForm';
import BackendForm from './Component/backend/BackendForm';
import AddClient from './Component/backend/AddClient';
import ViewClient from './Component/backend/ViewClient';
import Header from './Component/backend/Header';
import RightSidebar from './Component/backend/RightSidebar';
import Leftbar from './Component/backend/Leftbar';
import Login from './Component/Login';
import ProtectedRoute from './Component/ProtectedRoute';
import { AuthContext } from './AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import Unauthorized from './Component/Unauthorized';
import ViewProfile from './Component/backend/ViewProfile';
import RecordTest from './Component/backend/RecordTest';
// import AudioToText from './Component/backend/AudioToText';
import AudioToText from './Component/backend/AudioToText';
import AIFormUpdated from './Component/AIFormUpdated';
import ClientValidation from './Component/backend/ClientValidation';


// Import ToastContainer from react-toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Don't forget the CSS file

function App() {
  const { authState } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { showHeader, showSidebar } = useSelector((state) => state.visibility);

 

  return (
    <>
      {showHeader && <Header />}
      {authState.token && showSidebar ? (
        <>
          <RightSidebar />
          <Leftbar />
        </>
      ) : null}
      <Routes>
      
        <Route path='/not-authorized' element={<Unauthorized />} />
        <Route path="/:client_url" element={<ClientValidation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/record" element={<AudioToText />} />
        <Route
          path="/"
          element={<ProtectedRoute  element={ViewClient} fallbackElement={BackendForm}  roles={[1,2]} />}
        />
        <Route
          path="/backend"
          element={<ProtectedRoute element={BackendForm} roles={[1,2]} />}
        />
        <Route
          path="/add-client/:id?"
          element={<ProtectedRoute element={AddClient}  roles={[1]}/>}
        />
        <Route
          path="/view-clients"
          element={<ProtectedRoute element={ViewClient}  roles={[1]}/>}
        />
        <Route
          path="/view-clients/:id"
          element={<ProtectedRoute element={ViewClient} roles={[1]}/>}
        />

        <Route
          path="/view-profile/:id"
          element={<ProtectedRoute element={ViewProfile}  roles={[1,2]}/>}
        />
        <Route
          path="/view-settings/:id"
          element={<ProtectedRoute element={BackendForm}  roles={[1]}/>}
        />
        
      </Routes>
       {/* Add the ToastContainer component at the root level */}
       <ToastContainer />
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
