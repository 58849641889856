import React, { useState, useEffect,useRef,useCallback } from 'react';
import MainImageLogo from './MainImageLogo';
import HeaderLogo from './HeaderLogo';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { hideComponents,avtarsesssionfun } from '../store';
import AudioToText from './backend/AudioToText';
import Cookies from 'js-cookie';
import FaceDetection from './backend/FaceDetection';
import UseToast from './UseToast';
import UseTranslationData from './UseTranslationData';

export default function AiFormUpdateProd() {
    const [aiFormData, setAIFormData] = useState(null);
    const [translationData, setTranslationData] = useState(null);
    const [defaultLang, setDefaultLang] = useState(localStorage.getItem('default_lang') || 'en');
    const [siteBrandColor, setSiteBrandColor] = useState('');
    const [languages, setLanguages] = useState([]);
    const serverUrl='https://api.heygen.com';
    const [sessionInfo, setSessionInfo] = useState({});
    const [peerConnection, setPeerConnection] = useState({});
    const mediaRef = useRef(null); // Reference to the media element
    const [mediaCanPlay, setMediaCanPlay] = useState(false);
    const { transcript, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const [recognitionRunning, setRecognitionRunning] = useState(false);
    const [speechText,setSpeechText]=useState("");
    const silenceTimer = useRef(null);
    const inactivityTimer = useRef(null);
    const rizwanScriptStepRef = useRef(null);
    const rizwanScriptStepEnglishRef = useRef(null);
    const [sessionId,setSessionId]=useState(null);
    const [startSpeech,setStartSpeech]= useState(false);
  
    const { avtarsesssion } = useSelector((state) => state.visibility);
    const onspeechTextRef = useRef(null);
  
    const [rizwanScriptStep,setRizwanScriptStep]= useState(1);
    const [speechDurationInMs, setSpeechDurationInMs] = useState(0); // Store the speech duration in milliseconds
    const [isSpeaking, setIsSpeaking] = useState(false);
  
    const { showSuccessToast, showWarningToast } = UseToast(); // Use the custom hook

    const [isAvatarVisible, setIsAvatarVisible] = useState(false); // State to manage visibility

    const numberRef = useRef([]); // Initialize useRef with an empty array
    const buttonRef = useRef(null);


  
  
  
  
  
  
    const { client_url } = useParams();
    const dispatch = useDispatch();
  
    useEffect(()=>{  
      rizwanScriptStepRef.current=rizwanScriptStep;  
      
      if(rizwanScriptStep!==2){
        rizwanScriptStepEnglishRef.current=null;

      }
  
    },[rizwanScriptStep])
  
  
    
    useEffect(() => {
  
      const getColor = async () => {
        try {
          const response = await fetch(`/auth/aiform/frontend/${client_url}`);
  
          const data = await response.json();
          if (!response.ok) {
            
             alert(`Error: ${data.message}`);
          }else{
  
         
          const aiForm = data.aiForms[0];
          setAIFormData(aiForm);          
  
          const translation =  UseTranslationData(aiForm, defaultLang);
          setTranslationData(translation);
  
          if (translation) {
            setSiteBrandColor(translation.site_brand_color);
            localStorage.setItem('site_brand_color', translation.site_brand_color);
          }
  
          // Set languages
          setLanguages(aiForm.selected_lang_values);
  
          // Position the avatar logo
         // positionAvatarLogo(aiForm.avtar_page_image_location);
          
          // Handle live chat visibility
          handleLiveChatVisibility(aiForm, translation);
  
          
         
  
        }
          
        } catch (error) {
          console.error('Error fetching AI form data:', error);
        }
      };
  
      getColor();
      dispatch(hideComponents({ showHeader: false, showSidebar: false }));
  
      
  
  
      // dispatch(hideComponents());
  
  
  
    }, [defaultLang,dispatch]);
  
   
    
  
    const positionAvatarLogo = (location) => {
      let style = {};
      switch (location) {
        case 'top-right':
          style = { top: '0px', right: '5px' };
          break;
        case 'top-left':
          style = { top: '0px', left: '5px' };
          break;
        case 'bottom-right':
          style = { bottom: '0px', right: '5px' };
          break;
        case 'bottom-left':
          style = { bottom: '0px', left: '5px' };
          break;
        default:
          break;
      }
      return style;
    };
  
    const handleLiveChatVisibility = (aiForm, translation) => {
      if (aiForm.live_chat_enable === 'yes') {
        return (
            <>
          <a
            href={translation?.live_chat_url}
            style={{
              display: 'block',
              backgroundColor: translation?.site_brand_color,
              fontWeight: 'bold',
            }}
            target={aiForm.open_chat_new_tab === 'yes' ? '_blank' : '_self'}
            id="ai_live_chat"
          >
            {translation?.live_chat_button_text}
          </a>
          </>
        );
      }
      return null;
    };
  
    const handleLanguageChange = (lang) => {
      
  
      localStorage.setItem('default_lang', lang);
      setDefaultLang(lang);
    };
  
  
  
    const renderLanguageButtons = () => {
      return languages?.map((item) => (
        <div key={item.value} className={`${item.value}_language_sec language_btn`}>
          <button
            type="button"
            className={item.value === defaultLang ? 'current_lang' : ''}
            style={{
              backgroundColor: item.value === defaultLang ? siteBrandColor : '#fff',
              border: `1px solid ${siteBrandColor}`,
              color: item.value === defaultLang ? '#fff' : siteBrandColor,
            }}
            onClick={() => handleLanguageChange(item.value)}
          >
            {item.title}
          </button>
        </div>
      ));
    };
  
  
    const getTranslationDataForLanguage=(aiForm, lang)=> {
      if (aiForm.translations && aiForm.translations[lang]) {
          return aiForm.translations[lang];
      } else {
          console.warn(`No translation data found for language: ${lang}`);
          return null;
      }
    }
  
  
    // Function to reset the silence timer
    const resetSilenceTimer = () => {
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
      const blankSpaceTime = aiFormData?.blank_space; // Default to 5 seconds if not set
      silenceTimer.current = setTimeout(stopRecording, blankSpaceTime*1000);
    };
  
    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      const inactivityTimeLimit = aiFormData?.promo_time_limit; // Default to 10 seconds if not set
      inactivityTimer.current = setTimeout(playPromoMessage, inactivityTimeLimit* 60 * 1000);
    };
  
  
    useEffect(()=>{
  
      if(!aiFormData?.avtar_key || !aiFormData?.voice_key){
        return;
      }

      // if (buttonRef.current) {
      //   buttonRef.current.click(); // Simulate a click event
      // }
  
     createNewSession();
  
    },[aiFormData])
  
    
    const createNewSession = async () => {
      try {
        const avatar = aiFormData?.avtar_key;
        const voice = aiFormData?.voice_key;
  
        // Call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
        const sessionData = await newSession('high', avatar, voice);
       
        const { sdp: serverSdp, ice_servers2: iceServers } = sessionData;
  
        console.log("sessionData",sessionData)
        setSessionInfo(sessionData);
  
  
        dispatch(avtarsesssionfun(sessionData));
  
        // Create a new RTCPeerConnection
        const pc = new RTCPeerConnection({ iceServers });
  
        console.log("pc",pc)
        setPeerConnection(pc);
  
        // When audio and video streams are received, display them in the video element
        pc.ontrack = (event) => {
          console.log('Received the track');
          if (event.track.kind === 'audio' || event.track.kind === 'video') {
            if (mediaRef.current) {
              mediaRef.current.srcObject = event.streams[0]; // Set the stream dynamically
            }
          }
        };
        
  
        // When receiving a message, handle it
        pc.ondatachannel = (event) => {
          const dataChannel = event.channel;
          dataChannel.onmessage = onMessage;
        };
  
        // Set server's SDP as remote description
        const remoteDescription = new RTCSessionDescription(serverSdp);
        await pc.setRemoteDescription(remoteDescription);
  
        // Start the session and display it
        await startAndDisplaySession(pc,sessionData);
  
        
        
        document.body.classList.add('hide');

        setIsAvatarVisible(true); // Set visibility to true

  
        // const avatarSection = document.querySelector('.avtarsection');
        // avatarSection.style.display = 'flex';
  
       
  
  
      } catch (error) {
        console.error('Error creating session:', error);
        
      }
    };
  
    const newSession = async (quality, avatar_name, voice_id) => {
      const heygen_api_key = aiFormData?.heygen_api_key;
      const voice_rate = aiFormData?.voice_rate;
      const avtar_emotion = aiFormData?.avtar_emotion;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({
            quality,
            avatar_name,
            voice: {
              voice_id,
              rate: parseFloat(voice_rate),
              emotion: avtar_emotion,
            },
            video_encoding: 'H264'
          }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          return;
        }
  
        const data = await response.json();
        return data.data;
  
      } catch (error) {
        console.error('Error in newSession:', error);
      }
    };


     const reloadPage = () => {
        window.location.reload(); // This will reload the current page
      };
  
    const startAndDisplaySession = async (pc,sessionData) => {
      if (!sessionInfo) {
        console.error('Please create a connection first');
        return;
      }
  
      try {    
          
  
        // Create and set local SDP description
        const localDescription = await pc.createAnswer();
        await pc.setLocalDescription(localDescription);
  
        // Handle ICE candidate
        pc.onicecandidate = async ({ candidate }) => {
          console.log('Received ICE candidate:', candidate);
          if (candidate) {
            await handleICE(sessionData.session_id, candidate.toJSON());
          }
        };
  
        // Handle ICE connection state change
        pc.oniceconnectionstatechange = () => {
          console.log(`ICE connection state changed to: ${pc.iceConnectionState}`);
  
          if(pc.iceConnectionState==='disconnected'){
  
            reloadPage();
            
          }
        };
  
        // Start the session
        await startSession(sessionData.session_id, localDescription);
  
        // Adjust jitter buffer target
        pc.getReceivers().forEach((receiver) => {
          receiver.jitterBufferTarget = 500;
        });
  
      } catch (error) {
        console.error('Error in startAndDisplaySession:', error);
      }
    };
  
    const handleICE = async (session_id, candidate) => {
      const heygen_api_key = aiFormData?.heygen_api_key;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.ice`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({ session_id, candidate }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          return;
        }
  
        const data = await response.json();
        return data;
  
      } catch (error) {
        console.error('Error in handleICE:', error);
      }
    };
  
    const startSession = async (session_id, sdp) => {
      const heygen_api_key =aiFormData?.heygen_api_key;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.start`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({ session_id, sdp }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          throw new Error('Server error');
        }
  
        const data = await response.json();
        return data.data;
  
      } catch (error) {
        console.error('Error in startSession:', error);
      }
    };
  
    const onMessage=(event)=> {
      const message = event.data;
      console.log('Received message:', message);
    }
  
    useEffect(() => {
      if (mediaRef.current) {
        const mediaElement = mediaRef.current;
    
        const handleLoadedMetadata = async () => {
          setMediaCanPlay(true);
          try {
            mediaElement.muted = false;
            // Attempt to play the media
            await mediaElement.play();   
            
           // mediaElement.muted = false;

            // Play only for Non Rizwan Case
             if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){

              await welcomemsg(sessionInfo.session_id);
              
            }
            
          } catch (error) {
            console.error("Autoplay failed, user interaction is required.", error);
          }
        };
  
  
        const handleMediaEnded = () => {
          console.log("Live stream speaking is done.");
          // Trigger the action you want to perform here
          handleAvatarSpeechDone();
        };
    
    
        // Attach the event listener
        mediaElement.onloadedmetadata = handleLoadedMetadata;
  
        mediaElement.onended = handleMediaEnded;
    
        // Cleanup the event listener on component unmount
        return () => {
          mediaElement.onloadedmetadata = null;
          mediaElement.onended = null;
        };
      }
    }, [mediaRef.current, sessionInfo]);
  
    const handleAvatarSpeechDone = () => {
      // This function will be called when the live stream ends
      console.log("Avatar live stream has finished speaking.");
      // You can perform any action here
      // For example, reset a state, start a new session, or update the UI
    };
  
  const welcomemsg=async(sessionID,rizwanStatus=false)=>{ 

    const mediaElement = mediaRef.current;

   
  
    const predefinedText = rizwanStatus ? 'Hi Rizwan, welcome to our branch, how can I help you?' : translationData.avtar_welcome_text;
  
    await repeat(sessionID, predefinedText);   
    // setStartSpeech(!startSpeech);
    if(rizwanStatus){
      setRizwanScriptStep(prevStep => prevStep + 1);
  
    }
    
  }
  
  // repeat the text
  const repeat=async(session_id, text, speed = 2.0, pitch = 2.0)=> {
    const heygen_api_key = aiFormData?.heygen_api_key;
    const default_lang = localStorage.getItem('default_lang') ? localStorage.getItem('default_lang') : 'en';
  
    // Start translating text asynchronously
    let translatedTextPromise;
    // if (default_lang !== 'en') {
    //   translatedTextPromise = await translateText(text, default_lang);
    // } else {
    //   translatedTextPromise = Promise.resolve(text);
    // }
  
    translatedTextPromise = Promise.resolve(text);
  
    // Prepare the payload without waiting for the translation
    const payload = {
      session_id,
      speed,
      pitch
    };
  
    console.log("Request payload:", translatedTextPromise);
  
    try {
      // Await the translation and then add it to the payload
      payload.text = await translatedTextPromise;
  
      const response = await fetch(`${serverUrl}/v1/streaming.task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': heygen_api_key,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        throw new Error(`Server error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("data",data)

      if(aiFormData.enable_rizwan_event==='yes'){

        const durationInMilliseconds = data.data.duration_ms + 700; // Get duration from API (in milliseconds)
    
      setTimeout(() => {
        onAvatarSpeakingDone();
      }, durationInMilliseconds);

      
      if(rizwanScriptStep===2){
        rizwanScriptStepEnglishRef.current=true;
      }else{
        rizwanScriptStepEnglishRef.current=null;
      }

      }
  
      
      
  
      
      return data.data;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;
    }
  }
  
  
   // Function that will be called after the avatar finishes speaking
   const onAvatarSpeakingDone = () => {  
  
    if(aiFormData.enable_rizwan_event==='yes' && (rizwanScriptStepRef.current===3 || rizwanScriptStepRef.current===4)){ 
        
      console.log("Avatar finished speaking, perform actions here.");
      // showSuccessToast('Please Speak Now!');
        setStartSpeech(true);    
    }
   
  };
  
  
  const translateText=async(text, targetLanguage)=>{
    const response = await fetch(`https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|${targetLanguage}`);
    const data = await response.json();
    if (data.responseStatus !== 200) {
        throw new Error(data.responseDetails);
    }
    return data.responseData.translatedText;
  }
  
  
  
  
  
  
  const startConverting = (ses) => {  
    console.log("start talk",ses);
   if(aiFormData.enable_rizwan_event!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'none';
      document.getElementById("stoprecording").style.display = 'block';
    }
  
  };
  
   useEffect(() => {
      // Assign the function to the ref
      onspeechTextRef.current = (text) => {
        setSpeechText(text);
      };
    }, []);
  
  // Stop recording
  const stopRecording = async (liveStreamText,languageCode) => {
   
    if (!aiFormData) {
      console.error('translationData is not available');
      return;
    }
  
  
  
  //   SpeechRecognition.stopListening();
  //   setRecognitionRunning(false);
    console.log("stop talk");
  
    if(aiFormData.enable_rizwan_event!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'block';
      document.getElementById("stoprecording").style.display = 'none';
    }
  
  
  
    console.log("transcript",liveStreamText)
  
    const transaltionSpeechLive=UseTranslationData(aiFormData, languageCode);

    if(aiFormData.enable_rizwan_event!=='yes'){
       setTranslationData(transaltionSpeechLive);

    } 
  
    let modifiedText = transaltionSpeechLive?.modify_response_text || '';
    // let prompt = aiFormData?.enable_rizwan_event ?
    //  'સરસ, વહેલા આવવા બદલ આભાર! મેં એમીને જણાવી દીધું છે કે તમે અહીં છો, તે હમણાં જ બીજા ક્લાયન્ટ સાથે કામ કરી રહી છે. બેસો અને તે ટૂંક સમયમાં બહાર આવશે. શું હું તમને મદદ કરી શકું એવું બીજું કંઈ છે?':
     
    //  `${modifiedText} ${liveStreamText}`.trim();
  
    let prompt = `${modifiedText} ${liveStreamText}`.trim();
  
    let flag = false;


    if (aiFormData.enable_rizwan_event==='yes' && numberRef.current.includes(rizwanScriptStep)) {

      console.error('Sorry this step already exist');
      return;


    }
  
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===2){ 
        prompt = "Sarasa, vahēlā āvavā badala ābhāra! Mēṁ ēmīnē jaṇāvī dīdhuṁ chē kē tamē ahīṁ chō. Tēṇī phakta bījā grāhaka sāthē kāma karī rahī chē. Ā daramiyāna, śuṁ huṁ tamanē madada karī śakuṁ?";
        flag =true;
        rizwanScriptStepEnglishRef.current=null;
    }
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===3){ 
      prompt = "Oh, that's fantastic, did you know your advisor Amy, is also an avid traveler! One of our associates, directly behind me and to your right, would be happy to help you get up to 1000 Euros today. The current wait time is less than 5 minutes.  Anything else you need assistance with?";
      flag =true;
      rizwanScriptStepEnglishRef.current=null;
    }
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===4){
  
      prompt = "Happy to help,  Amy will be with you shortly, Have a great rest of your evening.";
      flag =true;
      rizwanScriptStepEnglishRef.current=null;
  
    }
  
     
  
    if (liveStreamText) {
      console.log("transcriptd",liveStreamText)
  
      try {

        if(aiFormData.enable_rizwan_event!=='yes'){

        let beforeResponseText = transaltionSpeechLive.before_response_text || '';

        console.log("beforeResponseText",beforeResponseText)


        const predefinedText = getRandomMessage(beforeResponseText);


        console.log("predefinedText",predefinedText)

  
        await repeat(sessionInfo.session_id, predefinedText);
  
         // Call talkToOpenAI to get the actual response
         const text = await talkToOpenAI(prompt);
  
         if (text) {
           
           await repeat(sessionInfo.session_id, text);        
          
         } else {
           console.error('Failed to get response from AI');
         }
  
        }else{
          numberRef.current.push(rizwanScriptStepRef.current);
          await repeat(sessionInfo.session_id, prompt);  
  
       
          if(flag){
            setRizwanScriptStep(prevStep => prevStep + 1);
            
        
          }
        }
  
       
  
      } catch (error) {
        console.error('Error talking to AI:', error);
      }
    }
  
    //resetInactivityTimer(); // Reset inactivity timer on stop recording
  } 
  
  
  const getRandomMessage = (inputString) => {
    const messages = inputString.split('|');
    const trimmedMessages = messages.map(message => message.trim());
    const randomIndex = Math.floor(Math.random() * trimmedMessages.length);
    return trimmedMessages[randomIndex];
  };
  
  
  const talkToOpenAI=async(prompt)=>{
    const userId=aiFormData?.user;
    const response = await fetch(`/openai/complete`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt,userId }),
    });
    if (response.status === 500) {
      console.error('Server error');
     
    } else {
      const data = await response.json();
      return data.text;
    }
  }
  
  
  useEffect(()=>{
      console.log("sessionInfo",avtarsesssion);
  
      if(avtarsesssion){
        setSessionId(avtarsesssion.session_id)
      }
  
  },[avtarsesssion])
  
    
  
  
    const playPromoMessage=async(sessionID) => {
      const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
  
      if(translationData && !rizwanStatus){
       
  
      let promoMessages=translationData.promo_message;
        const promoMessage = getRandomMessage(promoMessages);
  
        console.log("promoMessage", promoMessage);
  
        await repeat(sessionID, promoMessage);
      }
        // Implement your avatar speaking the promo message here
        // Example: await repeat(sessionInfo.session_id, promoMessage);
    };


    const resetSteps=()=>{
      setRizwanScriptStep(1);
      numberRef.current = [];  // Reset to an empty array or 0 if it's a single number ref
      showSuccessToast('Rizwan Script Steps Reset Successfully!');

    }
  
  
    
  
    if (!isAvatarVisible && !aiFormData) {
      return ( <><div>Loading...</div></>
       ) ;
    }else{
  
    return (
      <div className="main">       
  
        <div className="videoSectionWrap" id="sec1">         
            <MainImageLogo />          
  
          <div className="ai_concierge">
            <button type="button" 
            className="ai_concierge"
             id="ai_concierge_generate" 
             ref={buttonRef} // Assign the button ref
             style={{ backgroundColor: siteBrandColor,visibility:'hidden' }}
             onClick={() => createNewSession()}>
              {translationData?.home_screen_btn_text1}</button>
          </div>
  
          {handleLiveChatVisibility(aiFormData, translationData)}
  
  
          <div className="language_sec" id="language_sec" style={{ display: 'none' }}>
          {renderLanguageButtons()}
          </div>
        </div>
  
        <p id="status" style={{ display: 'none' }} />
  
        <div 
        className="videoSectionWrap avtarsection"         
        style={{ display: isAvatarVisible ? 'flex' : 'none' }} // Set the display dynamically
        >
          
  
              {sessionInfo.session_id && (
                  
                  <div className="videoWrap">
                  <HeaderLogo styles={positionAvatarLogo(aiFormData?.avtar_page_image_location)} />
                  <video id="mediaElement" ref={mediaRef} className="videoEle show" autoPlay muted />
                    <div className="imagebuttons">        
    
                        <AudioToText 
                        siteBrandColor={siteBrandColor} 
                        onstartConverting={startConverting}
                        onstopRecording={stopRecording}
                        translationData={translationData}
                        aiFormData={aiFormData}     
                        onplayPromoMessage={playPromoMessage}                  
                        onsessionInfo={sessionInfo?.session_id}
                        // onSpeechRecognitionEnd={(text) => console.log("Final text:", text)}
                        onstartspeech={startSpeech}
                        rizwanScriptStep={rizwanScriptStep}
                        setonstartspeech={()=>  setStartSpeech(!startSpeech)}
                        rizwanScriptStepEnglishRef={rizwanScriptStepEnglishRef.current}
    
                        />
                      
                      {aiFormData?.enable_face_detection==='yes' && (
                        <FaceDetection
                        onplayPromoMessage={welcomemsg}                  
                        onsessionInfo={sessionInfo?.session_id}
                        onstartspeech={()=>{
                          setStartSpeech(!startSpeech); 
                      
                        } }
                        styles={positionAvatarLogo(aiFormData?.face_detection_location)}
                        aiFormData={aiFormData}    
                        rizwanScriptStep={rizwanScriptStep}
                        
                        />
                        )}
    
    
                        
                        
                    </div>
                  </div>
                  
              ) 
              }

             <div id="back_to_menu" onClick={()=>resetSteps()}>

              {aiFormData.enable_rizwan_event==='yes' && (
                   
                   <button type="button" style={{ fontSize: '24px' }}>
                   <i className="fa fa-refresh" />
               </button>

              )}        
                
            </div>
  
            <div className="copyright_section">
              <span id="footer_text_copyright" style={{ backgroundColor: translationData?.welcom_text_back_color, color: translationData?.welcom_text_color }}> {translationData?.footer_text}</span>
            </div>
          
  
         
        </div>
      </div>
    );
            }
}
